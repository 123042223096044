import {
  CrudeTab,
} from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { TableTextAlignment, Order } from '@3m5/crude-frontend/dist/shared/types/commonTypes'

const crudeTabConfig = (): CrudeTab[] => {
  return [
    {
      entity: 'Website',
      hideEntity: false,
    },
    {
      entity: 'Service',
      hideEntity: false,
      columns: [
        {
          name: 'name',
          hideInTableView: false,
          columnWidth: 200,
          pinned: true,
          sort: Order.asc,
          align: TableTextAlignment.left,
        },
        {
          name: 'description',
          columnWidth: 200,
        },
        {
          name: 'processingCompany',
          hideInTableView: false,
        },
        {
          name: 'dataSecurityOfficer',
          hideInTableView: false,
        },
        {
          name: 'purpose',
          hideInTableView: false,
        },
        {
          name: 'technologies',
          hideInTableView: false,
        },
        {
          name: 'collectedData',
          hideInTableView: false,
        },
        {
          name: 'legalBasis',
          hideInTableView: false,
        },
        {
          name: 'location',
          hideInTableView: false,
        },
        {
          name: 'periodOfTime',
          hideInTableView: false,
        },
        {
          name: 'transferToCountries',
          hideInTableView: false,
        },
        {
          name: 'dataReceiver',
          hideInTableView: false,
        },
        {
          name: 'storageInformation',
          hideInTableView: false,
        },
        {
          name: 'history',
          hideInTableView: true,
        },
      ],
    },
    {
      entity: 'ServiceCategory',
      hideEntity: false,
    },
    {
      entity: 'Settings',
      hideEntity: false,
    },
    {
      entity: 'CustomerAccount',
      hideEntity: false,
    },
    {
      entity: 'Language',
      hideEntity: false,
    },
    {
      entity: 'User',
      hideEntity: false,
    },
  ]
}
export default crudeTabConfig
