import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../shared/components/FormComponents/ButtonComponent'
import Env from '../../shared/config/envConfig'
import { addCsrfHeaders } from '../../shared/config/csrf'
import { Box, Paper, Stack, Tab, Tabs, TextField, Theme, Typography, useTheme } from '@mui/material'
import DropDownComponent from '../../shared/components/FormComponents/DropDownComponent'
import { setMessage } from '../../store/settings/settings.actions'
import { EventType, Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { useDispatch } from 'react-redux'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const ExportImport: React.FC = () => {
  const theme: Theme = useTheme()
  const dispatch = useDispatch()

  const basePath = Env.basePath
  const requestHeaders: Headers = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')
  addCsrfHeaders(requestHeaders)

  const [locale, setLocale] = useState<string | undefined>()
  const [exportJson, setExportJson] = useState<string | undefined>()
  const [importJson, setImportJson] = useState<string | undefined>()

  const [languageOptions, setLanguageOptions] = useState(
    [
      {
        key: 'de-DE',
        value: 'de-DE',
      },
      {
        key: 'en-US',
        value: 'en-US',
      },
    ],
  )

  useEffect(() => {
    const restUrl = `${basePath}/api/locale`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          const languages = json
          const langOptions: any[] = []
          languages.map((elem: string) => {
            langOptions.push({
              key: elem,
              value: elem,
            })
          })
          setLanguageOptions(langOptions)
        }
      })
      .catch(err => console.log(err))
  },
  [],
  )

  const onExport = () => {
    const restUrl = `${basePath}/api/exportlabels/${locale}`
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          setExportJson(json)
          dispatch(setMessage({
            severity: Severity.success,
            message: 'Export erfolgreich durchgeführt',
            eventType: EventType.load,
          }))
        }
      })
      .catch(() => {
        dispatch(setMessage({
          severity: Severity.error,
          message: 'Export konnte nicht durchgeführt werden!',
          eventType: EventType.load,
        }))
      })
  }

  const onImport = () => {
    const restUrl = `${basePath}/api/importlabels/${locale}`
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
        body: JSON.stringify({ json: importJson }),
      })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          setImportJson(undefined)
          dispatch(setMessage({
            severity: Severity.success,
            message: 'Import erfolgreich durchgeführt',
            eventType: EventType.create,
          }))
        }
      })
      .catch(() => {
        dispatch(setMessage({
          severity: Severity.error,
          message: 'Import konnte nicht durchgeführt werden!',
          eventType: EventType.create,
        }))
      })
  }

  function TabPanel (props: TabPanelProps) {
    const {
      children,
      value,
      index,
      ...other
    } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps (index: number) {
    return {
      id: `tab-${index}`,
    }
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Export' {...a11yProps(0)} />
          <Tab label='Import' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Paper sx={{ padding: theme.spacing(2) }}>
          <Typography variant='h4'>Export</Typography>
          <Stack spacing={theme.spacing(2)}>
            <Stack sx={{ width: '300px' }}>
              <DropDownComponent
                options={languageOptions}
                value={languageOptions.find(elem => elem.key === locale)}
                disableClearable
                onChange={(value) => {
                  value?.key && setLocale(value?.key)
                  setExportJson(undefined)
                }}
              />
              <ButtonComponent variant='contained' onClick={() => onExport()} text='Export JSON' />
            </Stack>
            {exportJson &&
              <TextField
                multiline
                minRows={10}
                value={JSON.stringify(exportJson)}
              />}
          </Stack>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper sx={{ padding: theme.spacing(2) }}>
          <Typography variant='h4'>Import</Typography>
          <Stack spacing={theme.spacing(2)}>
            <TextField
              multiline
              minRows={10}
              value={JSON.stringify(importJson)}
              onChange={(event) => {
                try {
                  const JsonObj = JSON.parse(event.target.value)
                  setImportJson(JsonObj)
                } catch (e) {
                  dispatch(setMessage({
                    severity: Severity.error,
                    message: 'Kein gültiges JSON!',
                    eventType: EventType.create,
                  }))
                }
              }}
            />
            <Stack sx={{ width: '300px' }}>
              <DropDownComponent
                options={languageOptions}
                value={languageOptions.find(elem => elem.key === locale)}
                disableClearable
                onChange={(value) => {
                  value?.key && setLocale(value?.key)
                }}
              />
              <ButtonComponent
                variant='contained' onClick={() => onImport()} text='Import JSON'
                disabled={!importJson}
              />
            </Stack>
          </Stack>
        </Paper>
      </TabPanel>
    </Box>
  )
}
export default ExportImport
