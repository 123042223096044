import Cookies from 'universal-cookie'

const COOKIES = new Cookies()
export const CSRF_COOKIE = 'XSRF-TOKEN'
export const CSRF_HEADER = 'X-XSRF-TOKEN'

export const getCsrfToken = (): string => {
  return COOKIES.get(CSRF_COOKIE, { doNotParse: true })
}

export const addCsrfHeaders = (headers: Headers): Headers => {
  headers.set(CSRF_HEADER, getCsrfToken())
  return headers
}
