import { BooleanLayout, EditLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = (): EditLayout => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Service',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'isTemplate',
            layout: BooleanLayout.switch,
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'name',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'serviceCategory',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'cookieBannerId',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'language',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'description',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'processingCompany',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'dataProtectorOfCompany',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'dataProcessingPurposes',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'usedTechnologies',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'collectedData',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'legalBasis',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'placeOfDataProcessing',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'retentionPeriod',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'disclosureToThirdCountries',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'dataRecipient',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'storageInformation',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 3,
                    to: 8,
                  },
                },
                name: 'text',
                title: {
                  'de-DE': 'Texte',
                  'en-US': 'Texts',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Settings',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'language',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'name',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'acceptButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'denyButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'openButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'closeButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'showDetailsButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'saveSettingsButtonText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'privacyLinkText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'imprintLinkText',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'overviewContainerHeadline',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'detailsContainerHeadline',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'overviewContainerBody',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'detailsContainerBody',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'tabLabelCategory',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'tabLabelService',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'accordionButtonLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'descriptionLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'processingCompanyLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'dataSecurityOfficerLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'purposeLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'technologiesLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'collectedDataLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'legalBasisLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'locationLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'periodOfTimeLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'transferToCountriesLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 14,
                to: 14,
              },
            },
          },
          {
            name: 'dataReceiverLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 14,
                to: 14,
              },
            },
          },
          {
            name: 'storageInformationLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'historyLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'historyStatusLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 16,
                to: 16,
              },
            },
          },
          {
            name: 'historyTimestampLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 16,
                to: 16,
              },
            },
          },
          {
            name: 'historyAcceptedLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 17,
                to: 17,
              },
            },
          },
          {
            name: 'historyDeniedLabel',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 17,
                to: 17,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 5,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 6,
                    to: 7,
                  },
                },
                name: 'overviewContainer',
                title: {
                  'de-DE': 'Überlick',
                  'en-US': 'Overview',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 6,
                    to: 7,
                  },
                },
                name: 'detailContainer',
                title: {
                  'de-DE': 'Details',
                  'en-US': 'Detail',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 8,
                    to: 8,
                  },
                },
                name: 'tabLabels',
                title: {
                  'de-DE': 'Tab Labels',
                  'en-US': 'Tab Labels',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 9,
                    to: 17,
                  },
                },
                name: 'entries',
                title: {
                  'de-DE': 'Einträge',
                  'en-US': 'Entries',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
