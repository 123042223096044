import React from 'react'
import { Button, Icon } from '@mui/material'

export interface CustomObject {
  [key: string]: string
}

interface IButtonComponentProps {
  color?: 'primary' | 'secondary'
  disabled?: boolean
  disableElevation?: boolean
  variant?: 'contained' | 'text' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  startIcon?: JSX.Element | string
  text?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  link?: string
  styles?: CustomObject
}

export interface IButtonProps {
  color?: 'primary' | 'secondary'
  disabled?: boolean
  disableElevation?: boolean
  variant?: 'contained' | 'text' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  type?: 'button' | 'submit' | 'reset'
}

export const defaultSettings: IButtonProps = {
  disabled: false,
  disableElevation: true,
  size: 'medium',
  type: 'button',
}

const ButtonComponent: React.FC<IButtonComponentProps> = (props) => {
  const startIcon = props.startIcon
  const color: 'primary' | 'secondary' | undefined = props.color ? props.color : undefined
  const disabled = props.disabled ? props.disabled : defaultSettings.disabled
  const disableElevation = props.disableElevation ? props.disableElevation : defaultSettings.disableElevation
  const size: 'small' | 'medium' | 'large' | undefined = props.size ? props.size : defaultSettings.size
  const type: 'button' | 'submit' | 'reset' | undefined = props.type ? props.type : defaultSettings.type
  const link: string = props.link ? props.link : ''

  return (
    <Button
      style={props.styles}
      href={link}
      target='_blank'
      color={color}
      type={type}
      size={size}
      disabled={disabled}
      disableElevation={disableElevation}
      startIcon={startIcon && ((typeof startIcon === 'string') ? <Icon style={{ textTransform: 'none' }}>{startIcon}</Icon> : startIcon)}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.text}
    </Button>
  )
}
export default ButtonComponent
