{
  "common": {
    "editProfile": "Profil bearbeiten",
    "settings": "Einstellungen",
    "dashboard": "Übersicht",
    "logout": "Abmelden",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "submit": "Ok",
    "sort": "Sortieren",
    "back": "zurück zur Übersicht",
    "exportImport": "Export/Import",
    "export": "Produkte exportieren",
    "download": "Download",
    "copyService": "Service kopieren",
    "copyWebsite": "Website kopieren",
    "copySettings": "Einstellungen kopieren"
  },
  "settings": {
    "deleteSecret": "Secret löschen",
    "deleteSecretHint": "Sind Sie sicher, dass sie das Secret des Nutzers löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden!",
    "username": "Nutzername",
    "email": "E-Mail-Adresse",
    "name": "Klartextname",
    "role": "Rolle",
    "phone": "Telefon",
    "customer": "Kunde",
    "changePassword": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "confirmPassword": "Neues Passwort wiederholen"
  },
  "messages": {
    "copyServiceError": "Der Service konnte nicht kopiert werden.",
    "copyWebsiteError": "Die Website konnte nicht kopiert werden.",
    "copySettingsError": "Die Einstellungen konnten nicht kopiert werden."
  }
}
