import React, { useEffect, useRef, useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useSelector } from 'react-redux'
import { getUser } from '../../store/settings/settings.selectors'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const user = useSelector(getUser)
  const theme = useTheme()

  const [mobileLayout, setMobileLayout] = useState(false)

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  return (
    <>
      <Grid ref={rootRef} container className={classes.root}>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '10px', px: mobileLayout ? 2 : 4, py: mobileLayout ? 2 : 5 }}>
              <Typography variant='h4' sx={{ color: theme.palette.primary.contrastText }}>{`Hallo ${user?.userName}`}</Typography>
              <Typography variant='h6' sx={{ color: theme.palette.primary.contrastText }}>Willkommen zurück im Admin-Bereich.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default Dashboard
