/**
 * DropDownComponent
 *
 */

import React, { ChangeEvent, useEffect, useState } from 'react'

import { Autocomplete, Popper, TextField, Box, useTheme } from '@mui/material'

import { Theme } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import TooltipComponent from './TooltipComponent'
import LabelWithTooltipComponent from './LabelWithTooltipComponent'

export interface EnumElement {
  key: string
  value: string
  description?: string
}
interface IDropDownComponentData {
  options: EnumElement[]
  name?: string
  label?: string
  error?: boolean
  description?: string
  helperText?: string
  defaultValue?: EnumElement
  value?: EnumElement
  onChange?: (value?: EnumElement) => void
  disableClearable?: boolean
  disabled?: boolean
  fullWidth?: boolean
  width?: string
  placeholder?: string
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  limitTags?: number | undefined
  size?: 'medium' | 'small' | undefined
}

const DropDownComponent: React.FC<IDropDownComponentData> = (props) => {
  const theme: Theme = useTheme()
  const [value, setValue] = useState<EnumElement | null>(null)
  const [options, setOptions] = useState<EnumElement[]>([])
  const [error, setError] = useState<boolean>(false)

  const StyledPopper = function (props: any) {
    return (
      <Popper
        {...props}
        disablePortal={false}
        style={{
          width: props.anchorEl.clientWidth,
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
          inset: '5px auto auto 0px !important',
          '& .MuiAutocomplete-option:hover': {
            color: theme.palette.primary.main,
          },
          '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: theme.palette.grey.A100,
          },
          '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: 'transparent',
          },
        }}
        placement='bottom-end'
      />
    )
  }

  useEffect(() => {
    props.value ? setValue(props.value) : setValue(null)
  }, [props.value])

  useEffect(() => {
    props.options && setOptions(props.options)
  }, [props.options])

  useEffect(() => {
    props.error && setError(props.error)
  }, [props.error])

  const getClearIcon = () => {
    return (
      <>
        <DeleteIcon />
      </>
    )
  }

  return (
    <Autocomplete
      options={options}
      value={value}
      clearIcon={getClearIcon()}
      size={props.size}
      disableClearable={props.disableClearable}
      limitTags={props.limitTags}
      defaultValue={props.defaultValue}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option: EnumElement) => option.value}
      renderOption={(props, option) => {
        const element: JSX.Element = <span>{option.value}</span>
        return (
          <Box component='li' {...props}>
            {option.description
              ? (
                <TooltipComponent
                  title={option.description}
                  placement='right'
                  element={element}
                />)
              : <>{element}</>}
          </Box>
        )
      }}
      PopperComponent={StyledPopper}
      onChange={(event: ChangeEvent<any>, newValue: any) => {
        setValue(newValue)
        props.onChange && props.onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={props.helperText}
          required={props.disableClearable}
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'auto', display: 'flex', alignItems: 'center' },
          }}
          label={
            props.label &&
              <LabelWithTooltipComponent
                label={props.label}
                description={props.description}
              />
          }
          name={props.name}
          color={props.color}
          sx={{
            width: props.width ? props.width : props.fullWidth ? '100%' : '300px',
            marginTop: '16px',
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
              borderWidth: 2,
            },
          }}
          placeholder={props.placeholder}
        />
      )}
      disabled={props.disabled ? props.disabled : false}
    />
  )
}
export default DropDownComponent
