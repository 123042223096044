// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { Message, Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { useDispatch, useSelector } from 'react-redux'
import { getMessage } from '../../../store/settings/settings.selectors'
import * as settingsActions from '../../../store/settings/settings.actions'

const SnackbarComponent: React.FC = () => {
  const dispatch = useDispatch()

  const storedMessage: Message | undefined = useSelector(getMessage)
  const defaultHideDuration = 3

  const [open, setOpen] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<Message | undefined>()
  const [hideDuration, setHideDuration] = React.useState<number | null>(defaultHideDuration)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (storedMessage) {
      setMessage(storedMessage)
      setOpen(true)
      setHideDuration(message && message.severity === Severity.error ? null : defaultHideDuration)
    }
  },
  [storedMessage],
  )

  useEffect(() => {
    window.addEventListener('CrudeMessages', (({ detail }: CustomEvent) => {
      if (detail.eventType !== 'load') {
        dispatch(settingsActions.setMessage(detail))
      }
    }) as EventListener)
  },
  [],
  )

  return (
    <Snackbar open={open} autoHideDuration={hideDuration} onClose={handleClose}>
      <Alert variant='filled' onClose={handleClose} severity={message && message.severity} sx={{ width: '100%' }}>
        {message && message.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarComponent
