import {
  AppLayout, ToolbarListElement,
  ToolbarStyleElement,
} from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const appConfig = (): AppLayout => {
  const config: AppLayout = {
    version: 1,
    maxTreeViewLevel: 3,
    maxThumbnails: 3,
    useImageCropper: true,
    maxDropdownSize: 50,
    maxRowsPerDialogPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    initialPageSize: 10,
    showReferenceFilter: true,
    maxResponsiveTableColumns: 3,
    showStandardFilter: true,
    maxTextLinesInTableView: 3,
    maxForeignReferenceJumpLevel: 3,
    embeddedEditFormMainLevel: false,
    embeddedEditFormHigherLevel: false,
    button: {
      type: 'contained',
    },
    globalInputLayouts: {
      toolbar: [
        ToolbarStyleElement.bold, ToolbarStyleElement.italic, ToolbarListElement.bullet,
      ],
    },
    textfield: {
      type: 'outlined',
      margin: 'none',
      deleteIcon: 'delete',
      minRows: 3,
    },
    elements:
      [
        {
          id: 'tabs',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 0,
              to: 11,
            },
            vertical: {
              from: 0,
              to: 0,
            },
          },
        },
        {
          id: 'addIcon',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 11,
              to: 11,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'search',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: {
            horizontal: {
              from: 0,
              to: 1,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'filter',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: {
            horizontal: {
              from: 2,
              to: 10,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'data',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 0,
              to: 11,
            },
            vertical: {
              from: 2,
              to: 2,
            },
          },
        },
      ],
  }
  return config
}
export default appConfig
